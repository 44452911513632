import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import * as HamburgerIcon from '../svgs/icon-menu.svg'

const Index = props => {
    const [open, toggleOpen] = useState(false)
    const [scrollPosY, setScrollPosY] = useState(0)
    const [innerWidth, setInnerWidth] = useState(
        typeof window !== 'undefined' ? window.innderWidth : 0
    )
    const [isMobile, setIsMobile] = useState(false)
    const [showHeader, toggleHeader] = useState(true)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > scrollPosY) {
                toggleHeader(false)
            } else {
                toggleHeader(true)
            }
            setScrollPosY(window.scrollY)
        }

        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsMobile(true)
                setInnerWidth(window.innerWidth)
            } else {
                setIsMobile(false)
                setInnerWidth(window.innerWidth)
            }
        }

        if (window.innerWidth < 768) {
            setIsMobile(true)
        }
        setInnerWidth(window.innerWidth)

        document.addEventListener('scroll', handleScroll, { passive: true })
        window.addEventListener('resize', handleResize)

        return () => {
            // clean up the event handler when the component unmounts
            document.removeEventListener('scroll', handleScroll)
            window.removeEventListener('resize', handleResize)
        }
    }, [open, innerWidth, isMobile, showHeader, scrollPosY])

    const handleToggleOpen = value => {
        console.log('asdasdasd')

        if (open) {
            document.querySelector('body').classList.remove('mobile-menu-open')
        } else {
            document.querySelector('body').classList.add('mobile-menu-open')
        }

        toggleOpen(value === null || value === undefined ? !open : value)
    }

    if (isMobile) {
        return (
            <header
                className={
                    'header__mobile' +
                    (open ? ' open' : ' ') +
                    (showHeader || open ? '' : ' hide') +
                    (props.className ? ' ' + props.className : '')
                }
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="topbar">
                                <div>
                                    <Link
                                        to="/"
                                        onClick={() => handleToggleOpen(false)}
                                    >
                                        <h2 className="font-family-lora">
                                            Ian Banatoski
                                        </h2>
                                    </Link>
                                </div>
                                <div>
                                    <HamburgerIcon
                                        onClick={() => handleToggleOpen(!open)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={'slider' + (open ? ' open' : ' ')}>
                                <div className="content">
                                    <nav className="margin-y-5">
                                        <ul>
                                            <li className="margin-bottom-3">
                                                <Link
                                                    to="/about"
                                                    onClick={() =>
                                                        handleToggleOpen(false)
                                                    }
                                                >
                                                    <h2>About</h2>
                                                </Link>
                                            </li>
                                            <li className="margin-bottom-3">
                                                <Link
                                                    to="/projects"
                                                    onClick={() =>
                                                        handleToggleOpen(false)
                                                    }
                                                >
                                                    <h2>Projects</h2>
                                                </Link>
                                            </li>
                                            <li className="margin-bottom-3">
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="https://d2jotzgct8q460.cloudfront.net/files/Resume-Ian-Banatoski.pdf"
                                                    onClick={() =>
                                                        handleToggleOpen(false)
                                                    }
                                                >
                                                    <h2>Resume</h2>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="mailto:ibanatoski@gmail.com"
                                        className="secondary-button hover__red"
                                    >
                                        Hire Me
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    } else {
        return (
            <header
                className={
                    'header__desktop  padding-y-1' +
                    (props.className ? ' ' + props.className : '')
                }
            >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 d-flex align-items-center justify-content-between">
                            <Link to="/">
                                <h2 className="font-family-lora">
                                    Ian Banatoski
                                </h2>
                            </Link>
                            <div className="d-flex">
                                <nav className="d-flex align-items-center">
                                    <ul>
                                        <li>
                                            <Link to="/about">About</Link>
                                        </li>
                                        <li>
                                            <Link to="/projects">Projects</Link>
                                        </li>
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href="https://d2jotzgct8q460.cloudfront.net/files/Resume-Ian-Banatoski.pdf"
                                            >
                                                Resume
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="mailto:ibanatoski@gmail.com"
                                    className="secondary-button"
                                >
                                    Hire Me
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Index
