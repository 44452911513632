/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import '../scss/styles.scss'

import Header from './header.js'

const Layout = props => {
    return (
        <React.Fragment>
            <Header />
            <main className={props.className}>{props.children}</main>
            <footer className="padding-top-6 padding-bottom-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1 order-lg-1 col-8 offset-2 margin-bottom-3">
                            <img
                                src="https://d2jotzgct8q460.cloudfront.net/svgs/peep-76.svg"
                                alt="Peep 76 SVG"
                            />
                        </div>
                        <div className="col-lg-6 order-lg-0 align-self-center">
                            <h2 className="margin-bottom-2">
                                Why end it here?
                            </h2>
                            <h2 className="weight-500">
                                Reach out to me at{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="mailto:ibanatoski@gmail.com"
                                    className="red-underline text-decoration-none"
                                >
                                    ibanatoski@gmail.com
                                </a>{' '}
                                and lets talk about data-viz{' '}
                                <span role="img" aria-label="graph-emoji">
                                    📈
                                </span>{' '}
                                , a new song you learned on the guitar{' '}
                                <span role="img" aria-label="guitar-emoji">
                                    🎸
                                </span>{' '}
                                , or anything you like{' '}
                                <span role="img" aria-label="hamburger-emoji">
                                    🍔
                                </span>{' '}
                                <span role="img" aria-label="motorcycle-emoji">
                                    🏍
                                </span>{' '}
                                <span role="img" aria-label="camping-emoji">
                                    🏕
                                </span>{' '}
                                !
                            </h2>
                        </div>
                    </div>
                </div>
            </footer>
            <section className="padding-y-1">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 d-flex justify-content-end">
                            <p className="display-inline-block footnote">
                                Thank You to{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://twitter.com/pablostanley"
                                    className="color-twitter-blue text-decoration-none"
                                >
                                    @pablostanley
                                </a>{' '}
                                for his{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.openpeeps.com/"
                                >
                                    Open Peeps
                                </a>{' '}
                                library! An amazing open source character
                                library!
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
